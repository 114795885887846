import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from 'App'

import { MaterialUIControllerProvider } from 'context'
import { Provider } from 'react-redux'
import store from './redux/store'

const container = document.getElementById('app')
const root = createRoot(container)

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <App />
      </MaterialUIControllerProvider>
    </BrowserRouter>
  </Provider>,
)
