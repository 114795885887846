import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'

import MDInput from 'components/MDInput'
function NotificationAdd() {
  const token = localStorage.getItem('userToken')
  const [message, setMessage] = useState([])
  const [error, setError] = useState(null)
  const navigate = useNavigate()
  const [address, setAddress] = useState('')
  const validateAddress = (value) => {
    const regex = /^0x[a-fA-F0-9]{40}$/

    const addresses = value.split(',').map((addr) => addr.trim())
    if (addresses.some((address) => !regex.test(address))) {
      setError('Please enter valid wallet address(es).')
    } else {
      setError('')
    }
  }

  const handleAddressChange = (e) => {
    const value = e.target.value
    setAddress(value)
    validateAddress(value)
  }

  const handleMessageChange = (e) => setMessage(e.target.value)

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!error && address) {
      console.log('Valid Ethereum address submitted:', address)
    } else {
      console.log('Please fix the errors.')
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}users/send-notification`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          sender_type: 'Admin',
          notification_tag: 'multipleUsers',
          message: message,
          address: address,
        }),
      })

      const data = await response.json()

      if (!response.ok) {
        throw new Error(data.message || 'Failed to add new user')
      }
      navigate('/notifications')
    } catch (err) {
      setError(err.message)
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                <MDTypography variant="h6" color="white">
                  Send Notification
                </MDTypography>
              </MDBox>

              <MDBox pt={4} pb={13} px={5}>
                <MDBox component="form" role="form" onSubmit={handleSubmit}>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      id="address"
                      value={address}
                      placeholder="Enter wallet addresses"
                      label="Wallet Address"
                      variant="standard"
                      fullWidth
                      onChange={handleAddressChange}
                      required
                    />

                    {error && <p style={{ color: 'red' }}>{error}</p>}
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Message"
                      variant="standard"
                      fullWidth
                      value={message}
                      onChange={handleMessageChange}
                      required
                      helperText={!message && 'Message is required'}
                    />
                  </MDBox>

                  <MDBox mt={4} mb={1}>
                    <MDButton variant="gradient" color="info" type="submit" disabled={!!error}>
                      Send
                    </MDButton>{' '}
                    <MDButton variant="gradient" color="secondary" type="button" onClick={() => navigate('/notifications')}>
                      Cancel
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  )
}

export default NotificationAdd
