import { configureStore } from '@reduxjs/toolkit'
// import { adminReducer } from './adminRelated/adminSlice'
// import { subadminReducer } from './subadminRelated/subadminSlice'
import { userReducer } from './slice/user-slice'
const store = configureStore({
  reducer: {
    // admin: adminReducer,

    user: userReducer,
  },
})

export default store
