import Grid from '@mui/material/Grid'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import pattern from 'assets/images/profile-bg.png'
import Icon from '@mui/material/Icon'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import Card from '@mui/material/Card'
import { useEffect, useState, useCallback } from 'react'
import MyDocument from 'components/PDF'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useParams, useNavigate } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast'
import DialogContentText from '@mui/material/DialogContentText'
import MDSelect from 'components/MDSelect'
import TextField from '@mui/material/TextField'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
function UserView() {
  const { id } = useParams()
  const navigate = useNavigate()
  const token = localStorage.getItem('userToken')

  const [userData, setUserData] = useState(null)
  const [open, setOpen] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState('')
  const [notificationMessage, setNotificationMessage] = useState('')
  const [description, setDescription] = useState('')

  const statusMessages = {
    Under_review: 'We are reviewing your documents.',
    Processing: 'We have received your documents and are processing them.',
    Block: 'Your account has been blocked by the administrator.',
    Resubmission: 'You must submit the document again.',
    Rejected: 'Unfortunately, your document has not met the required standards and has been rejected.',
    Completed: 'We’re excited to let you know that your new NFT has been successfully created!',
  }

  const handleSelectChange = (value) => {
    setSelectedStatus(value)
    setNotificationMessage(statusMessages[value] || '')
  }

  const handleClose = () => setOpen(false)
  const handleClickOpen = () => setOpen(true)
  const handleBackClick = () => navigate(-1)

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const options = { day: 'numeric', month: 'long', year: 'numeric' }
    const formattedDate = date.toLocaleDateString('en-GB', options)
    const formattedTime = date.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    })
    return `${formattedDate} ${formattedTime}`
  }

  const fetchUserData = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}users/getUserDetailByID/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      const results = await response.json()
      setUserData(results.data.user)
    } catch (error) {
      console.error(error.response?.data?.message || 'Fetch failed. Please try again.')
    }
  }, [id, token])

  const onClickSubmit = async (event) => {
    event.preventDefault()
    const body = { status: selectedStatus, description, notificationMessage }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}users/updateUserStatus/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      })

      if (response.ok) {
        toast.success('Status updated Successfully')
        fetchUserData()
        setSelectedStatus('')
        setDescription('')
        handleClose()
      } else {
        const errorData = await response.json()
        toast.error(errorData.message || 'Unknown error occurred')
      }
    } catch (error) {
      toast.error(error.message || 'Upload failed. Please try again.')
    }
  }
  useEffect(() => {
    fetchUserData()
  }, [fetchUserData])

  // useEffect(() => {
  //   if (userData !== null) {
  //     setUserData(userData || '')
  //   }
  // }, [userData])
  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <Toaster />
      <MDBox mt={8}>
        <MDBox mb={3}>
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} lg={8}>
              {' '}
              <MDButton variant="gradient" color="info" onClick={handleBackClick}>
                <Icon sx={{ fontWeight: 'bold' }}>arrow_back</Icon>
                &nbsp;Back
              </MDButton>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} xl={12}>
                  <Card
                    sx={{
                      background: 'linear-gradient(0deg, #222222 48.62%, #1E3811 100%)',
                      position: 'relative',
                    }}
                  >
                    <MDBox
                      position="absolute"
                      top={0}
                      left={0}
                      width="100%"
                      height="100%"
                      sx={{
                        backgroundImage: `url(${pattern})`,
                        backgroundSize: 'cover',
                      }}
                    />
                    <MDBox position="relative" zIndex={2} p={2}>
                      <MDBox borderRadius="lg" display="flex" justifyContent="space-between" alignItems="center">
                        {' '}
                        <MDBox color="white" p={1} lineHeight={0} display="inline-block">
                          <Icon fontSize="default">person</Icon>
                        </MDBox>
                        <MDBox ml="auto" lineHeight={0}>
                          <MDButton variant="outlined" color="white" onClick={() => handleClickOpen()} sx={{ background: 'transparent' }}>
                            <Icon sx={{ fontWeight: 'bold' }}>edit</Icon>
                            &nbsp;Change Status
                          </MDButton>
                        </MDBox>
                      </MDBox>

                      <MDBox display="flex" flexDirection={{ xs: 'column', md: 'row' }} flexWrap="wrap" justifyContent="space-between" gap={1} sx={{ mt: 2, pb: 1.5 }}>
                        <MDBox display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
                          <MDTypography variant="h6" color="white" fontWeight="medium">
                            Wallet Address: &nbsp;
                          </MDTypography>{' '}
                          <MDTypography
                            variant="h6"
                            color="white"
                            fontWeight="regular"
                            opacity={0.8}
                            sx={{
                              fontSize: 14,
                              wordBreak: 'break-all',
                              maxWidth: '100%',
                            }}
                          >
                            {userData && userData.address ? userData.address : '-'}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                      <MDBox display="flex" flexDirection={{ xs: 'column', md: 'row' }} flexWrap="wrap" justifyContent="space-between" gap={1} sx={{ pb: 1.5 }}>
                        <MDBox display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
                          <MDTypography variant="h6" color="white" fontWeight="medium">
                            First Name: &nbsp;
                          </MDTypography>
                          <MDTypography variant="h6" color="white" fontWeight="regular" opacity={0.8} mr={6}>
                            {userData && userData.personalDetails ? userData.personalDetails.first_name : '-'}
                          </MDTypography>

                          <MDTypography variant="h6" color="white" fontWeight="medium">
                            Last Name: &nbsp;
                          </MDTypography>
                          <MDTypography variant="h6" color="white" fontWeight="regular" opacity={0.8}>
                            {userData && userData.personalDetails ? userData.personalDetails.last_name : '-'}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                      <MDBox display="flex" alignItems="center">
                        <MDTypography variant="h6" color="white" fontWeight="medium">
                          Passport Number: &nbsp;
                        </MDTypography>
                        <MDTypography variant="h6" color="white" fontWeight="regular" opacity={0.8}>
                          {userData && userData.personalDetails ? userData.personalDetails.passport_number : '-'}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>

                <Grid item xs={12} xl={12}>
                  <Card id="delete-account" sx={{ border: '2.5px solid #1E3811' }}>
                    <MDBox pt={2} px={2} display="flex" alignItems="center">
                      <AccountBalanceWalletIcon />
                      <MDTypography variant="h6" fontWeight="medium" sx={{ ml: 1 }}>
                        Payment and Status Details
                      </MDTypography>
                    </MDBox>
                    <MDBox p={2}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                          <MDBox
                            position="relative"
                            zIndex={2}
                            p={2}
                            borderRadius="lg"
                            sx={{
                              border: '1px solid #1E3811',
                            }}
                          >
                            <MDTypography variant="button">Payment Details</MDTypography>

                            <MDBox mt={1}>
                              <Grid container spacing={1}>
                                <Grid item xs={12} xl={8}>
                                  {/* <MDBox
                                display="flex"
                                flexDirection={{ xs: "column", md: "row" }}
                                flexWrap="wrap"
                                justifyContent="space-between"
                                gap={1}
                              > */}
                                  {/* First Transaction Hash */}
                                  <MDBox display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
                                    <MDTypography variant="h6" color="dark" fontWeight="medium">
                                      First Transaction Hash: &nbsp;
                                    </MDTypography>
                                    <MDTypography
                                      variant="body2"
                                      sx={{
                                        wordBreak: 'break-all',
                                        maxWidth: '100%',
                                      }}
                                    >
                                      {userData?.paymentHash || '-'}
                                    </MDTypography>
                                  </MDBox>
                                </Grid>{' '}
                                <Grid item xs={12} xl={2}>
                                  <MDBox display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
                                    <MDTypography variant="h6" color="dark" fontWeight="medium">
                                      Payment Status: &nbsp;
                                    </MDTypography>
                                    <MDTypography
                                      variant="h6"
                                      color="text"
                                      sx={{
                                        fontSize: 14,
                                        display: 'inline-block',
                                      }}
                                    >
                                      {userData?.paymentStatus ? (
                                        userData.paymentStatus === 'Verified' ? (
                                          <MDTypography variant="body2" color="success" fontWeight="medium">
                                            Verified
                                          </MDTypography>
                                        ) : userData.paymentStatus === 'InCoupon' ? (
                                          <MDTypography variant="body2" color="warning" fontWeight="medium">
                                            InCoupon
                                          </MDTypography>
                                        ) : userData.paymentStatus === 'UnVerified' ? (
                                          <MDTypography variant="body2" color="error" fontWeight="medium">
                                            UnVerified
                                          </MDTypography>
                                        ) : (
                                          <MDTypography variant="body2">Pending</MDTypography>
                                        )
                                      ) : (
                                        '-'
                                      )}
                                    </MDTypography>
                                  </MDBox>
                                </Grid>{' '}
                                <Grid item xs={12} xl={2}>
                                  <MDBox display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
                                    <MDTypography variant="h6" color="dark" fontWeight="medium">
                                      Token ID: &nbsp;
                                    </MDTypography>
                                    <MDTypography variant="body2">{userData?.tokenId || '0'}</MDTypography>
                                  </MDBox>
                                </Grid>
                                {/* </MDBox> */}
                              </Grid>
                              <Grid container spacing={1}>
                                <Grid item xs={12} xl={8}>
                                  <MDBox display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
                                    <MDTypography variant="h6" color="dark" fontWeight="medium">
                                      Second Transaction Hash: &nbsp;
                                    </MDTypography>
                                    <MDTypography
                                      variant="body2"
                                      sx={{
                                        wordBreak: 'break-all',
                                        maxWidth: '100%',
                                      }}
                                    >
                                      {userData?.paymentHashSecond || '-'}
                                    </MDTypography>
                                  </MDBox>
                                </Grid>

                                <Grid item xs={12} xl={2}>
                                  <MDBox display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
                                    <MDTypography variant="h6" color="dark" fontWeight="medium">
                                      Payment Status: &nbsp;
                                    </MDTypography>
                                    {userData?.paymentStatusSecond ? (
                                      <MDTypography
                                        variant="body2"
                                        color={
                                          userData.paymentStatusSecond === 'Verified'
                                            ? 'success'
                                            : userData.paymentStatusSecond === 'InCoupon'
                                            ? 'warning'
                                            : userData.paymentStatusSecond === 'UnVerified'
                                            ? 'error'
                                            : 'text'
                                        }
                                        fontWeight="medium"
                                      >
                                        {userData.paymentStatusSecond}
                                      </MDTypography>
                                    ) : (
                                      <MDTypography variant="body2">-</MDTypography>
                                    )}
                                  </MDBox>
                                </Grid>

                                <Grid item xs={12} xl={2}>
                                  <MDBox display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
                                    <MDTypography variant="h6" color="dark" fontWeight="medium">
                                      Token ID: &nbsp;
                                    </MDTypography>
                                    <MDTypography variant="body2">{userData?.tokenIdSecond || '0'}</MDTypography>
                                  </MDBox>
                                </Grid>
                              </Grid>

                              <MDBox display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
                                <MDTypography variant="h6" color="dark" fontWeight="medium">
                                  Coupon Available: &nbsp;
                                </MDTypography>
                                <MDTypography variant="body2">{userData?.isCoupon === true ? 'Available' : 'Not Available'}</MDTypography>
                              </MDBox>
                            </MDBox>
                          </MDBox>
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <MDBox
                            position="relative"
                            zIndex={2}
                            p={2}
                            borderRadius="lg"
                            sx={{
                              border: '1px solid #1E3811',
                            }}
                          >
                            <MDTypography variant="button">Status Details</MDTypography>{' '}
                            <MDBox mt={1}>
                              <MDTypography
                                variant="h6"
                                color="dark"
                                fontWeight="medium"
                                mb={2}
                                // mt={3}
                              >
                                {userData?.status
                                  ? userData.status === 'Under_review'
                                    ? 'Under Review'
                                    : userData.status === 'Processing'
                                    ? 'Approved! Creating NFT Soon 😊'
                                    : userData.status
                                  : 'Document Submission Incomplete'}
                              </MDTypography>
                              <MDBox ml="auto" lineHeight={0} color="dark">
                                <MDTypography variant="caption" color="text" fontWeight="regular" sx={{ fontSize: 15, color: '#484747' }}>
                                  {userData?.description ? userData.description : ' '}
                                </MDTypography>
                              </MDBox>
                            </MDBox>{' '}
                          </MDBox>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={8}>
              <Card id="delete-account">
                <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2}>
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    Uploaded Documents
                  </MDTypography>
                  <MDBox display="flex" alignItems="flex-start">
                    <MDBox color="text" mr={0.5} lineHeight={0}>
                      <Icon color="inherit" fontSize="small">
                        date_range
                      </Icon>
                    </MDBox>
                    <MDTypography variant="button" color="text" fontWeight="regular">
                      {userData ? formatDate(userData.updatedAt ?? userData.createdAt ?? new Date()) : '-'}
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox pt={1} pb={2} px={2}>
                  <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                    <MDBox component="li" display="flex" justifyContent="space-between" alignItems="flex-start" bgColor="grey-100" borderRadius="lg" p={3} mb={1} mt={2}>
                      <MDBox width="100%" display="flex" flexDirection="column">
                        <MDBox display="flex" justifyContent="space-between" alignItems={{ xs: 'flex-start', sm: 'center' }} flexDirection={{ xs: 'column', sm: 'row' }} mb={2}>
                          {userData && userData.documents && userData.documents ? (
                            <Grid item xs={12}>
                              <MDBox
                                borderRadius="lg"
                                p={1}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                  border: ({ borders: { borderWidth, borderColor } }) => `${borderWidth[1]} solid ${borderColor}`,
                                }}
                              >
                                <MDBox>
                                  <MyDocument pdfFile={userData.documentUrls} />
                                </MDBox>
                              </MDBox>
                            </Grid>
                          ) : (
                            <Grid item xs={12}>
                              <MDBox
                                borderRadius="lg"
                                p={3}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                  border: ({ borders: { borderWidth, borderColor } }) => `${borderWidth[1]} solid ${borderColor}`,
                                }}
                              >
                                <MDBox textAlign="center" mb={2}>
                                  <MDTypography variant="h5" fontWeight="medium">
                                    Passport
                                  </MDTypography>
                                  <MDTypography variant="body2">No document has been uploaded yet.</MDTypography>
                                </MDBox>
                              </MDBox>
                            </Grid>
                          )}
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card sx={{ height: '100%' }}>
                <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
                  <MDTypography variant="h6" fontWeight="medium">
                    Face Recognition
                  </MDTypography>
                  <MDBox display="flex" alignItems="flex-start">
                    <MDBox color="text" mr={0.5} lineHeight={0}>
                      <Icon color="inherit" fontSize="small">
                        date_range
                      </Icon>
                    </MDBox>
                    <MDTypography variant="button" color="text" fontWeight="regular">
                      {userData ? formatDate(userData.updatedAt ?? userData.createdAt ?? new Date()) : '-'}
                    </MDTypography>
                  </MDBox>
                </MDBox>

                <MDBox pt={1} pb={2} px={2}>
                  <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                    <MDBox component="li" display="flex" justifyContent="space-between" alignItems="flex-start" bgColor="grey-100" borderRadius="lg" p={3} mb={1} mt={2}>
                      <MDBox width="100%" display="flex" flexDirection="column">
                        <MDBox display="flex" justifyContent="space-between" alignItems={{ xs: 'flex-start', sm: 'center' }} flexDirection={{ xs: 'column', sm: 'row' }} mb={2}>
                          {userData && userData.faceDocument && userData.faceDocument ? (
                            <Grid item xs={12}>
                              <MDBox
                                borderRadius="lg"
                                p={3}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                  border: ({ borders: { borderWidth, borderColor } }) => `${borderWidth[1]} solid ${borderColor}`,
                                }}
                              >
                                <MDBox>
                                  <img
                                    src={userData.faceCaptureUrls}
                                    alt="User"
                                    style={{
                                      maxWidth: '100%',
                                      borderRadius: '8px',
                                    }}
                                  />
                                </MDBox>
                              </MDBox>
                            </Grid>
                          ) : (
                            <Grid item xs={12}>
                              <MDBox
                                borderRadius="lg"
                                p={3}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                  border: ({ borders: { borderWidth, borderColor } }) => `${borderWidth[1]} solid ${borderColor}`,
                                }}
                              >
                                <MDBox textAlign="center" mb={2}>
                                  <MDTypography variant="h5" fontWeight="medium">
                                    Face Recognition
                                  </MDTypography>
                                  <MDTypography variant="body2">No live face capture has been posted as of yet.</MDTypography>
                                </MDBox>
                              </MDBox>
                            </Grid>
                          )}
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
                <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2}>
                  <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    Uploaded Photo
                  </MDTypography>
                  <MDBox display="flex" alignItems="flex-start">
                    <MDBox color="text" mr={0.5} lineHeight={0}>
                      <Icon color="inherit" fontSize="small">
                        date_range
                      </Icon>
                    </MDBox>
                    <MDTypography variant="button" color="text" fontWeight="regular">
                      {userData ? formatDate(userData.updatedAt ?? userData.createdAt ?? new Date()) : '-'}
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox pt={1} pb={2} px={2}>
                  <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                    <MDBox component="li" display="flex" justifyContent="space-between" alignItems="flex-start" bgColor="grey-100" borderRadius="lg" p={3} mb={1} mt={2}>
                      <MDBox width="100%" display="flex" flexDirection="column">
                        <MDBox display="flex" justifyContent="space-between" alignItems={{ xs: 'flex-start', sm: 'center' }} flexDirection={{ xs: 'column', sm: 'row' }} mb={2}>
                          {userData && userData.photoDocument && userData.photoDocument ? (
                            <Grid item xs={12}>
                              <MDBox
                                borderRadius="lg"
                                p={3}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                  border: ({ borders: { borderWidth, borderColor } }) => `${borderWidth[1]} solid ${borderColor}`,
                                }}
                              >
                                <MDBox>
                                  <img
                                    src={userData.photoUrls}
                                    alt="User"
                                    style={{
                                      maxWidth: '100%',
                                      borderRadius: '8px',
                                    }}
                                  />
                                </MDBox>
                              </MDBox>
                            </Grid>
                          ) : (
                            <Grid item xs={12}>
                              <MDBox
                                borderRadius="lg"
                                p={3}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                  border: ({ borders: { borderWidth, borderColor } }) => `${borderWidth[1]} solid ${borderColor}`,
                                }}
                              >
                                <MDBox textAlign="center" mb={2}>
                                  <MDTypography variant="h5" fontWeight="medium">
                                    Photo
                                  </MDTypography>
                                  <MDTypography variant="body2">No photo has been uploaded yet.</MDTypography>
                                </MDBox>
                              </MDBox>
                            </Grid>
                          )}
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: onClickSubmit,
        }}
      >
        <DialogTitle>Update User Status</DialogTitle>
        <DialogContent>
          <DialogContentText mb={4}>Determine which status to change for the user.</DialogContentText>
          <MDSelect value={selectedStatus} onChange={handleSelectChange} />
          <MDBox mb={2} mt={4}>
            <TextField
              id="description"
              name="description"
              label="Additional Description"
              type="text"
              fullWidth
              margin="dense"
              onChange={(e) => setDescription(e.target.value)}
              required
            />{' '}
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleClose}>Cancel</MDButton>
          <MDButton type="submit">Submit</MDButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  )
}

export default UserView
