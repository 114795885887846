import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import MDInput from 'components/MDInput'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

function SubadminEdit() {
  const { id } = useParams()
  const token = localStorage.getItem('userToken')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)
  const navigate = useNavigate()
  const [userService, setUserService] = useState([])
  const handleUserChange = (event, newValue) => {
    setUserService(newValue)
  }

  useEffect(() => {
    const fetchTeacherData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}auth/get-subadmin-byID/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        const data = await response.json()
        console.log(data.data)
        if (response.ok) {
          setName(data.data.name)
          setEmail(data.data.email)

          setUserService(data.data.services)
        } else {
          throw new Error(data.message || 'Failed to fetch student data')
        }
      } catch (err) {
        setError(err.message)
      }
    }
    fetchTeacherData()
  }, [id, token])

  const handleSubmit = async (e) => {
    e.preventDefault()

    const requestBody = {
      name: name,
      email: email,
      services: userService,
    }

    // Only include password if it has been updated
    if (password.trim() !== '') {
      requestBody.password = password
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}auth/edit-subadmin/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      })

      const data = await response.json()

      if (!response.ok) {
        throw new Error(data.message || 'Failed to add new user')
      }
      navigate('/subadmin')
    } catch (err) {
      setError(err.message)
    }
  }
  console.log(userService)
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                <MDTypography variant="h6" color="white">
                  Edit Subadmin
                </MDTypography>
              </MDBox>

              <MDBox pt={4} pb={13} px={5}>
                <MDBox component="form" role="form" onSubmit={handleSubmit}>
                  <MDBox mb={2}>
                    <MDInput type="text" focused label="Name" variant="standard" fullWidth required value={name} onChange={(e) => setName(e.target.value)} />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput type="email" focused label="Email" variant="standard" fullWidth required value={email} onChange={(e) => setEmail(e.target.value)} autoComplete="off" />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput type="password" label="Password" variant="standard" fullWidth focused onChange={(e) => setPassword(e.target.value)} autoComplete="new-password" />
                  </MDBox>
                  <MDBox>
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      options={serviceOptions}
                      disableCloseOnSelect
                      value={serviceOptions.filter((option) => Array.isArray(userService) && userService.some((user) => user.service_id === option.service_id))}
                      getOptionLabel={(option) => option.service}
                      onChange={handleUserChange}
                      renderOption={(props, option, { selected }) => {
                        const { key, ...optionProps } = props
                        return (
                          <li key={key} {...optionProps}>
                            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                            {option.service}
                          </li>
                        )
                      }}
                      style={{ width: 500 }}
                      renderInput={(params) => <TextField {...params} label="Select Permissions" placeholder="Permissions for Subadmin" />}
                    />
                  </MDBox>
                  {error && (
                    <MDBox mt={2}>
                      <MDTypography color="error">{error}</MDTypography>
                    </MDBox>
                  )}
                  <MDBox mt={4} mb={1}>
                    <MDButton variant="gradient" color="info" type="submit">
                      Save
                    </MDButton>{' '}
                    <MDButton variant="gradient" color="secondary" type="button" onClick={() => navigate('/subadmin')}>
                      Cancel
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  )
}

const serviceOptions = [
  { service: 'Users', service_id: 1 },
  { service: 'Under Review Users', service_id: 2 },
  { service: 'Create NFT Users', service_id: 3 },
  { service: 'Block Users', service_id: 4 },
  { service: 'Resubmission Users', service_id: 5 },
  { service: 'Rejected Users', service_id: 6 },
  { service: 'Completed Users', service_id: 7 },

  { service: 'Notifications', service_id: 9 },
  { service: 'Coupons', service_id: 10 },
]

export default SubadminEdit
