import Dashboard from 'layouts/dashboard'
import User from 'layouts/user'
// import Payment from 'layouts/payment'
import SignIn from 'layouts/authentication/sign-in'
import UserView from 'layouts/user/view'
import UserReview from 'layouts/user/under-review'
import Resubmission from 'layouts/user/resubmission'
import Rejected from 'layouts/user/rejected'
import CreateNft from 'layouts/user/create-nft'
import Completed from 'layouts/user/completed'
import Block from 'layouts/user/block'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import AllUser from 'layouts/user/all-users'
import Notification from 'layouts/notification'
import NotificationAdd from 'layouts/notification/add'
import Coupon from 'layouts/coupon'
import CouponAdd from 'layouts/coupon/add'
import Subadmin from 'layouts/subadmin'
import SubadminAdd from 'layouts/subadmin/add'
import Icon from '@mui/material/Icon'
// import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin'
import SubadminView from 'layouts/subadmin/view'
import SubadminEdit from 'layouts/subadmin/edit'
const routes = [
  {
    type: 'collapse',
    name: 'Dashboard',
    key: 'dashboard',
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: '/dashboard',
    service_id: 0,
    component: <Dashboard />,
  },
  {
    type: 'collapse',
    name: 'Users',
    key: 'user',
    icon: <Icon fontSize="small">people</Icon>,
    route: '/user',
    service_id: 1,
    component: <User />,
  },
  {
    type: 'collapse',
    name: 'Under-Review',
    key: 'under-review',
    icon: <Icon fontSize="small">pending</Icon>,
    route: '/under-review',
    service_id: 2,
    component: <UserReview />,
  },
  {
    type: 'collapse',
    name: 'Create-NFT',
    key: 'create-nft',
    icon: <Icon fontSize="small">edit_note</Icon>,
    route: '/create-nft',
    service_id: 3,
    component: <CreateNft />,
  },
  {
    type: 'collapse',
    name: 'Block',
    key: 'block',
    icon: <Icon fontSize="small">block</Icon>,
    route: '/block',
    service_id: 4,
    component: <Block />,
  },

  {
    type: 'collapse',
    name: 'Resubmission',
    key: 'resubmission',
    icon: <Icon fontSize="small">wrap_text</Icon>,
    route: '/resubmission',
    service_id: 5,
    component: <Resubmission />,
  },
  {
    type: 'collapse',
    name: 'Rejected',
    key: 'rejected',
    icon: <Icon fontSize="small">highlight_off</Icon>,
    route: '/rejected',
    service_id: 6,
    component: <Rejected />,
  },
  {
    type: 'collapse',
    name: 'Completed',
    key: 'completed',
    icon: <Icon fontSize="small">check_circle</Icon>,
    route: '/completed',
    service_id: 7,
    component: <Completed />,
  },

  {
    type: 'sub',
    name: 'View User',
    key: 'user_view',
    icon: <Icon fontSize="small">table_view</Icon>,
    route: '/user/view/:id',
    component: <UserView />,
  },
  // {
  //   type: 'collapse',
  //   name: 'Payment Request',
  //   key: 'payment',
  //   icon: <CurrencyBitcoinIcon />,
  //   route: '/payment',
  //   service_id: 8,
  //   component: <Payment />,
  // },
  {
    type: 'collapse',
    name: 'Notifications',
    key: 'notifications',
    icon: <Icon> notifications </Icon>,
    route: '/notifications',
    service_id: 9,
    component: <Notification />,
  },
  {
    type: 'sub',
    name: 'Send Notification',
    key: 'send-notifications',
    icon: <Icon> notifications </Icon>,
    route: '/notification/add',
    component: <NotificationAdd />,
  },

  {
    type: 'collapse',
    name: 'Coupons',
    key: 'coupons',
    icon: <LocalOfferIcon />,
    route: '/coupons',
    service_id: 10,
    component: <Coupon />,
  },
  {
    type: 'sub',
    name: 'Add Coupons',
    key: 'coupons-add',
    icon: <LocalOfferIcon />,
    route: '/coupon/add',
    component: <CouponAdd />,
  },
  {
    type: 'collapse',
    name: 'Subadmin',
    key: 'subadmin',
    icon: <Icon> people </Icon>,
    route: '/subadmin',
    component: <Subadmin />,
  },
  {
    type: 'sub',
    name: 'Add Subadmin',
    key: 'subadmin-add',
    icon: <Icon> people </Icon>,
    route: '/subadmin/add',
    component: <SubadminAdd />,
  },
  {
    type: 'sub',
    name: 'Edit Subadmin',
    key: 'subadmin-edit',
    icon: <Icon> people </Icon>,
    route: '/subadmin/edit/:id',
    component: <SubadminEdit />,
  },
  {
    type: 'sub',
    name: 'View Subadmin',
    key: 'subadmin-view',
    icon: <Icon> people </Icon>,
    route: '/subadmin/view/:id',
    component: <SubadminView />,
  },
  {
    type: 'auth',
    name: 'Sign In',
    key: 'sign-in',
    icon: <Icon fontSize="small">login</Icon>,
    route: '/admin/sign-in',
    component: <SignIn />,
  },
  {
    type: 'sub',
    name: 'AllUsers',
    key: 'alluser',
    icon: <Icon fontSize="small">people</Icon>,
    route: '/all-users',
    component: <AllUser />,
  },
]

export default routes
