import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import MDSelect from 'components/MDSelect'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import DataTable from 'examples/Tables/DataTable'
import Icon from '@mui/material/Icon'
import React, { useEffect, useState, useCallback } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import toast, { Toaster } from 'react-hot-toast'
import MDInput from 'components/MDInput'
import editSvg from 'assets/images/edit.svg'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import { RotatingLines } from 'react-loader-spinner'
import { useNavigate, Link } from 'react-router-dom'
import InputAdornment from '@mui/material/InputAdornment'
import { DateRangePicker } from 'rsuite'
import Pagination from '@mui/material/Pagination'
import 'rsuite/dist/rsuite.min.css'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Tooltip from '@mui/material/Tooltip'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

function User() {
  const navigate = useNavigate()
  const token = localStorage.getItem('userToken')
  const [open, setOpen] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState('')
  const [user_id, setUserId] = useState('')
  const [description, setDescription] = useState('')
  const [notificationMessage, setNotificationMessage] = useState('')
  const [openMenu, setOpenMenu] = useState(false)
  const [userOptions, setUserOptions] = useState([])
  const [subAdminUserOptions, setSubadminUserOptions] = useState([])
  const [selectedSubAdmin, setSelectedSubAdmin] = useState(null)
  const [subAdminError, setSubAdminError] = useState(false)
  const [usersError, setUsersError] = useState(false)
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [userData, setUserData] = useState(null)
  const [dateRange, setDateRange] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [openDeleteBox, setOpenDeleteBox] = useState(false)
  const [selectedUserId, setSelectedUserId] = useState(null)
  // let searchValue = ''
  // const handleSearchChange = (event) => {
  //   searchValue = event.target.value
  // }

  // const handleSearchSubmit = (event) => {
  //   event.preventDefault()
  //   const trimmedSearchValue = searchValue.trim()
  //   fetchData(currentPage, trimmedSearchValue, dateRange)
  // }

  const [searchValue, setSearchValue] = useState('')

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value)
  }

  const handleSearchSubmit = (event) => {
    event.preventDefault()
    const trimmedSearchValue = searchValue.trim()
    fetchData(currentPage, trimmedSearchValue, dateRange)

    setSearchValue('')
  }

  const handleUserChange = (event, newValue) => {
    setUsers(newValue)
    setUsersError(newValue && newValue.length === 0)
  }

  const handleClickOpen = (user) => {
    setUserId(user._id)
    setOpen(true)
  }

  const handleClickEdit = () => {
    getSubadminList()
    getUnAssignedUserList()
    setOpenMenu(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleCloseMenu = () => {
    setOpenMenu(false)
  }

  const handleSelectChange = (value) => {
    setSelectedStatus(value)

    if (value === 'Under_review') {
      setNotificationMessage('We are reviewing your documents.')
    } else if (value === 'Processing') {
      setNotificationMessage('We have received your documents and are processing them.')
    } else if (value === 'Block') {
      setNotificationMessage('Your account has been blocked by the administrator.')
    } else if (value === 'Resubmission') {
      setNotificationMessage('You must submit the document again.')
    } else if (value === 'Rejected') {
      setNotificationMessage('Unfortunately, your document has not met the required standards and has been rejected.')
    } else if (value === 'Completed') {
      setNotificationMessage('We’re excited to let you know that your new NFT has been successfully created!')
    }
  }

  const [data, setData] = useState({
    columns: [
      {
        Header: 'Sr.No',
        accessor: 'srno',
        align: 'left',
      },
      {
        Header: 'Wallet Address',
        accessor: 'address',
        align: 'left',
      },
      // {
      //   Header: 'First Name',
      //   accessor: 'first_name',
      //   align: 'left',
      // },
      // {
      //   Header: 'Last Name',
      //   accessor: 'last_name',
      //   align: 'left',
      // },
      // {
      //   Header: 'Passport Number',
      //   accessor: 'passport_number',
      //   align: 'left',
      // },
      { Header: 'payment status', accessor: 'paymentStatuses', align: 'left' },
      {
        Header: 'created At',
        accessor: 'createdAt',
        align: 'left',
      },
      {
        Header: 'updated At',
        accessor: 'updatedAt',
        align: 'left',
      },
      { Header: 'status', accessor: 'currentstatus', align: 'left' },

      { Header: 'assigned to subadmin', accessor: 'assigned_subadmin', align: 'center' },

      { Header: 'action', accessor: 'action', align: 'center' },
    ],
    rows: [],
    totalRows: 0,
  })

  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString)
    const options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    }
    return date.toLocaleDateString('en-US', options)
  }

  const fetchSubadminData = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}auth/get-subadmin-profile`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      const results = await response.json()

      setUserData(results.data)
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Upload failed. Please try again.'
      console.log(errorMessage)
    }
  }, [token])

  const handleToCopyContent = (addr) => {
    navigator.clipboard.writeText(addr).then(() => {
      toast.success(`Address  successfully copied!`)
    })
  }
  const fetchData = useCallback(
    async (currentPage, searchValue, range = null) => {
      setLoading(true)
      try {
        const baseUrl = `${process.env.REACT_APP_API_BASE_URL}users/getUserList/All/${currentPage}`

        const dateQuery = range ? `?startDate=${range[0]?.toISOString()}&endDate=${range[1]?.toISOString()}` : ''
        const searchQuery = searchValue ? `${dateQuery ? '&' : '?'}search=${searchValue}` : ''
        const url = `${baseUrl}${dateQuery}${searchQuery}`

        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        if (!response.ok) {
          toast.error('The user information is not found.')
        }
        const jsonData = await response.json()
        setLoading(false)
        const userResult = jsonData.data.user.users

        const rows = Array.isArray(userResult)
          ? userResult.map((users, index) => ({
              srno: (currentPage - 1) * 10 + index + 1,
              address: users.address ? (
                <MDBox lineHeight={1} textAlign="left">
                  <MDTypography display="block" variant="h6" color="text" fontWeight="medium">
                    {users.address}
                    <Tooltip title="Copy to clipboard">
                      <MDButton onClick={() => handleToCopyContent(users.address)} sx={{ minWidth: 'auto', padding: 0, ml: 1 }}>
                        <ContentCopyIcon />
                      </MDButton>{' '}
                    </Tooltip>
                  </MDTypography>
                  <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                    First Name: {users.first_name ? users.first_name : '-'}
                  </MDTypography>

                  <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                    Last Name: {users.last_name ? users.last_name : '-'}
                  </MDTypography>
                  <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                    Passport Number: {users.passport_number ? users.passport_number : '-'}
                  </MDTypography>
                  <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                    Token Id: {users.tokenId}
                  </MDTypography>
                </MDBox>
              ) : (
                '-'
              ),
              paymentStatuses: users.paymentStatus ? (
                users.paymentStatus === 'Verified' ? (
                  <MDTypography display="block" variant="caption" color="success" fontWeight="medium">
                    Verified
                  </MDTypography>
                ) : users.paymentStatus === 'InCoupon' ? (
                  <MDTypography display="block" variant="caption" color="warning" fontWeight="medium">
                    InCoupon
                  </MDTypography>
                ) : users.paymentStatus === 'UnVerified' ? (
                  <MDTypography display="block" variant="caption" color="error" fontWeight="medium">
                    UnVerified
                  </MDTypography>
                ) : (
                  <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                    Pending
                  </MDTypography>
                )
              ) : (
                ' '
              ),
              // first_name: users.first_name ? users.first_name : '-',
              // last_name: users.last_name ? users.last_name : '-',
              // passport_number: users.passport_number ? users.passport_number : '-',
              createdAt: (
                <MDBox lineHeight={1} textAlign="left">
                  <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                    {formatDate(users.createdAt)}
                  </MDTypography>
                </MDBox>
              ),
              updatedAt: (
                <MDBox lineHeight={1} textAlign="left">
                  <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                    {formatDate(users.updatedAt)}
                  </MDTypography>
                </MDBox>
              ),
              currentstatus: (
                <MDBox display="flex" alignItems="center">
                  <MDTypography variant="caption" color="text" fontWeight="medium" borderRadius="10rem" sx={{ border: '1px solid #000', padding: 1, marginRight: 1 }}>
                    {users.status ? (users.status === 'Under_review' ? 'Under Review' : users.status === 'Processing' ? 'Create NFT' : users.status) : '-'}
                  </MDTypography>
                  <MDButton onClick={() => handleClickOpen(users)} sx={{ minWidth: 'auto', padding: 0 }}>
                    <img src={editSvg} alt="edit icon" />
                  </MDButton>
                </MDBox>
              ),

              assigned_subadmin: (
                <MDBox display="flex" alignItems="center">
                  {userData && userData.role === 'Subadmin'
                    ? userData.name
                    : users.subadminResponse && users.subadminResponse.name
                    ? users.subadminResponse.name + ' (' + users.subadminResponse.email + ')'
                    : '-'}
                </MDBox>
              ),
              action: (
                <>
                  {' '}
                  <MDButton component={Link} to={`view/${users._id}`} size="large">
                    <Icon color="success">visibility</Icon>
                  </MDButton>
                  {/* <MDTypography component="a" href={`user/view/${users._id}`} mr={2}>
                    <Icon color="success">visibility</Icon>
                  </MDTypography> */}
                  <MDButton component="a" onClick={() => handleClickOpenDeleteBox(users._id)} size="large">
                    <Icon color="error">delete</Icon>
                  </MDButton>
                </>
              ),
            }))
          : []

        const totalRows = jsonData.data.user.totalUsersCount

        setData((prevData) => ({
          ...prevData,
          rows: rows,
          totalRows: totalRows,
        }))
      } catch (error) {
        console.log(error)
      }
    },
    // eslint-disable-next-line
    [currentPage, token],
  )

  const handleDelete = useCallback(async () => {
    if (!selectedUserId) return
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}users/delete-user/${selectedUserId}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      })

      if (!response.ok) throw new Error('Failed to delete user')

      toast.success('User deleted successfully')

      await fetchData(currentPage, searchValue, dateRange)
      setOpenDeleteBox(false)
    } catch (error) {
      toast.error('Failed to delete user')
    }
  }, [selectedUserId, fetchData, currentPage, searchValue, token, dateRange])

  const onClickSubmit = async (event) => {
    event.preventDefault()
    const bdy = { status: selectedStatus, description: description, notificationMessage: notificationMessage }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}users/updateUserStatus/${user_id}`, {
        method: 'POST',
        body: JSON.stringify(bdy),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })

      if (response.ok) {
        toast.success('Status updated Successfully')

        fetchData(currentPage, searchValue, dateRange)
        setSelectedStatus('')
        setDescription('')
        handleClose()
      } else {
        const errorData = await response.json()
        toast.error(errorData.message || 'Unknown error occurred')
      }
    } catch (error) {
      const errorMessage = error.message || 'Upload failed. Please try again.'

      toast.error(errorMessage)
    }
  }

  const onClickSubmitAssignedUsers = async (event) => {
    event.preventDefault()

    let isValid = true

    if (!selectedSubAdmin) {
      setSubAdminError(true)
      isValid = false
    } else {
      setSubAdminError(false)
    }

    if (users.length === 0) {
      setUsersError(true)
      isValid = false
    } else {
      setUsersError(false)
    }

    if (!isValid) {
      return
    }

    try {
      const bdy = { user_ids: users.map((user) => user._id), subadmin_id: selectedSubAdmin._id }
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}users/assignedUsersToSubadmin`, {
        method: 'POST',
        body: JSON.stringify(bdy),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })

      if (response.ok) {
        toast.success('Users assigned to subadmin Successfully')
        setSelectedSubAdmin(null)
        setUsers([])
        fetchData(currentPage, searchValue, dateRange)
        getUnAssignedUserList()
        handleCloseMenu()
      } else {
        toast.error('Unknown error occurred')
      }
    } catch (error) {
      const errorMessage = error.message || 'Upload failed. Please try again.'

      toast.error(errorMessage)
    }
  }

  const getSubadminList = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}auth/get-subadmin-list`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })

      if (!response.ok) {
        toast.error('The user list is not found.')
      }

      const jsonData = await response.json()

      setSubadminUserOptions(jsonData.data.userData)
    } catch (error) {
      console.log('Error fetching data:', error.message)
    }
  }, [token])

  const getUnAssignedUserList = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}users/get-unassigned-user-list`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })

      if (!response.ok) {
        toast.error('The user list is not found.')
      }

      const jsonData = await response.json()

      setUserOptions(jsonData.data)
    } catch (error) {
      console.log('Error fetching data:', error.message)
    }
  }, [token])

  useEffect(() => {
    if (!token) {
      navigate('/')
    } else {
      fetchSubadminData()
    }
  }, [fetchSubadminData, token, navigate])

  useEffect(() => {
    fetchData(currentPage, searchValue, dateRange)
  }, [currentPage, fetchData, searchValue, dateRange])

  const [rowsPerPage] = useState(10)

  const totalPagesRowCount = data?.totalRows || 0
  const totalPages = Math.ceil(totalPagesRowCount / rowsPerPage)

  const handleDateRangeChange = (newRange) => {
    setDateRange(newRange)
    setCurrentPage(1)
    fetchData(1, searchValue, newRange)
  }
  const handleCurrentPageChange = (event, value) => {
    setCurrentPage(value)
    fetchData(value, searchValue, dateRange)
  }

  const handleClickOpenDeleteBox = (user_id) => {
    setSelectedUserId(user_id)
    setOpenDeleteBox(true)
  }

  const handleCloseDeleteBox = () => {
    setOpenDeleteBox(false)
    setSelectedUserId(null)
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Toaster />

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white" mr={1}>
                  User List
                </MDTypography>
                {userData && userData.role === 'Subadmin' ? (
                  ''
                ) : (
                  <MDBox display="flex" flexWrap="wrap" gap={1} justifyContent="flex-end">
                    <MDButton component="a" href="/all-users" variant="contained" color="white" size="medium">
                      <Icon color="white">list</Icon>
                    </MDButton>
                    <MDButton variant="outlined" color="white" size="medium" onClick={() => handleClickEdit()}>
                      <Icon color="white">edit</Icon>Assigned to Subadmin
                    </MDButton>
                  </MDBox>
                )}
              </MDBox>
              <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                <Grid item>
                  <MDBox display="flex" alignItems="center" mt={3} px={2}>
                    <DateRangePicker
                      onChange={(range) => {
                        handleDateRangeChange(range)
                      }}
                    />
                  </MDBox>
                </Grid>
                <Grid item>
                  <MDBox display="flex" alignItems="center" mt={3} px={2}>
                    <form onSubmit={handleSearchSubmit}>
                      <MDInput
                        type="text"
                        placeholder="Search by address"
                        fullWidth
                        value={searchValue} // Make it a controlled input
                        id="search_value"
                        onChange={handleSearchChange}
                        InputProps={{
                          sx: {
                            paddingRight: 0,
                            borderRadius: '9.434rem !important',
                          },
                          endAdornment: (
                            <InputAdornment position="end" sx={{ marginRight: 0 }}>
                              <MDButton
                                variant="gradient"
                                color="info"
                                type="submit"
                                sx={{
                                  minWidth: 'auto',
                                  padding: '6px 12px',
                                  borderRadius: '50%',
                                }}
                              >
                                <Icon fontSize="small">search</Icon>
                              </MDButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </form>
                  </MDBox>
                </Grid>
              </Grid>

              <MDBox pt={3}>
                {loading ? (
                  <MDTypography align="center">
                    <RotatingLines strokeColor="grey" strokeWidth="5" animationDuration="0.75" width="96" visible={true} />
                  </MDTypography>
                ) : (
                  <DataTable table={data} isSorted={false} entriesPerPage={false} showTotalEntries={false} canSearch={true} noEndBorder />
                )}

                <MDBox display="flex" justifyContent="center" alignItems="center" mt={10} mb={2} px={3} gap={2}>
                  <Stack spacing={2}>
                    <Pagination count={totalPages} color="primary" variant="outlined" shape="rounded" page={currentPage} onChange={handleCurrentPageChange} />
                  </Stack>{' '}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: onClickSubmit,
        }}
      >
        <DialogTitle>Update User Status</DialogTitle>
        <DialogContent>
          <DialogContentText mb={4}>Determine which status to change for the user.</DialogContentText>
          <MDSelect value={selectedStatus} onChange={handleSelectChange} />
          <MDBox mb={2} mt={4} p={1}>
            <MDInput type="text" label="Addition Description" variant="standard" fullWidth name="description" required onChange={(e) => setDescription(e.target.value)} />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openMenu}
        onClose={handleCloseMenu}
        PaperProps={{
          component: 'form',
          onSubmit: onClickSubmitAssignedUsers,
          style: {
            width: '500px',
          },
        }}
      >
        <DialogTitle>Assign Users to Subadmin</DialogTitle>
        <DialogContent>
          <DialogContentText mb={4}>Submitting the users to the subadmin for verification</DialogContentText>
          <MDBox mb={2} mt={4} p={1}>
            <Autocomplete
              disablePortal
              options={subAdminUserOptions}
              getOptionLabel={(option) => option.name + ' (' + option.email + ')' || ''}
              value={selectedSubAdmin}
              isOptionEqualToValue={(option, value) => option._id === value}
              onChange={(event, newValue) => setSelectedSubAdmin(newValue)}
              renderInput={(params) => (
                <TextField {...params} label="Select Subadmin" placeholder="Select Subadmin" error={subAdminError} helperText={subAdminError ? 'Please select a subadmin' : ''} />
              )}
              required
            />
          </MDBox>
          <MDBox mb={2} p={1}>
            <Stack spacing={3}>
              <Autocomplete
                multiple
                id="tags-outlined"
                options={userOptions}
                getOptionLabel={(option) => option.address || ''}
                filterSelectedOptions
                value={users}
                isOptionEqualToValue={(option, value) => option._id === value._id}
                onChange={handleUserChange}
                renderInput={(params) => (
                  <TextField {...params} label="Select User" placeholder="Users" error={usersError} helperText={usersError ? 'Please select at least one user' : ''} />
                )}
                required
              />
            </Stack>
          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMenu}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDeleteBox}
        onClose={handleCloseDeleteBox}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <DialogTitle sx={{ textAlign: 'center', position: 'relative', paddingTop: '40px' }}>
          <HighlightOffIcon
            fontSize="large"
            sx={{
              position: 'absolute',
              top: '20px',
              left: '50%',
              transform: 'translateX(-50%)',
              color: 'red',
            }}
          />
          <MDTypography mt={4} display="block" variant="h4" color="dark " fontWeight="medium">
            Deleting User
          </MDTypography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ textAlign: 'center' }}>Do you want to delete this user?</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <MDButton onClick={handleCloseDeleteBox} color="secondary">
            No
          </MDButton>
          <MDButton onClick={handleDelete} color="info">
            Yes
          </MDButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  )
}

export default User
