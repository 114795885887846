import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  requeststatus: 'idle',
  userDetails: [],
  tempDetails: [],
  loading: true,
  currentUser: localStorage.getItem('user') || null,
  currentRole: (localStorage.getItem('user') || {}).role || null,
  currentAuthToken: localStorage.getItem('userToken') || null,
  error: null,
  response: null,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    authRequest: (state) => {
      state.loading = true
      state.requeststatus = 'loading'
    },

    doneSuccess: (state, action) => {
      state.userDetails = action.payload.data
      state.tempDetails = action.payload.data
      state.loading = false
      state.error = null
      state.response = null
    },

    getError: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const { authRequest, doneSuccess, getError } = userSlice.actions

export const userReducer = userSlice.reducer
