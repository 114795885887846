import { useState, useEffect } from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import Sidenav from 'examples/Sidenav'
import theme from 'assets/theme'
import themeDark from 'assets/theme-dark'
import routes from 'routes'
import { useNavigate } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'
import { useMaterialUIController, setMiniSidenav } from 'context'
import brandWhite from 'assets/images/logo.png'
import brandDark from 'assets/images/logo.png'
import 'custom.css'
const isTokenExpired = (token) => {
  if (!token) return true
  const decodedToken = jwtDecode(token)
  const currentTime = Date.now() / 1000
  return decodedToken.exp < currentTime
}
export default function App() {
  const [controller, dispatch] = useMaterialUIController()
  const { miniSidenav, direction, layout, sidenavColor, transparentSidenav, whiteSidenav, darkMode } = controller
  const [onMouseEnter, setOnMouseEnter] = useState(false)
  const { pathname } = useLocation()

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false)
      setOnMouseEnter(true)
    }
  }

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true)
      setOnMouseEnter(false)
    }
  }

  useEffect(() => {
    document.body.setAttribute('dir', direction)
  }, [direction])

  useEffect(() => {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
  }, [pathname])

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse)
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />
      }

      return null
    })

  const userToken = localStorage.getItem('userToken')
  const navigate = useNavigate()

  useEffect(() => {
    if (userToken) {
      if (isTokenExpired(userToken)) {
        localStorage.removeItem('userToken')
        localStorage.removeItem('userData')
        navigate('/admin/sign-in')
      } else if (pathname === '/' || pathname === '/admin/sign-in') {
        navigate('/dashboard')
      }
    } else {
      navigate('/admin/sign-in')
    }
  }, [userToken, navigate, pathname])

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {userToken ? (
        <>
          {layout === 'dashboard' && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                brandName=""
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
            </>
          )}

          <Routes>
            {getRoutes(routes)}
            <Route path="/dashboard" element={<Navigate to="/dashboard" />} />
          </Routes>
        </>
      ) : (
        <Routes>
          {getRoutes(routes)}
          <Route path="/" element={<Navigate to="/admin/sign-in" />} />
        </Routes>
      )}
    </ThemeProvider>
  )
}
