import { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'
import MDButton from 'components/MDButton'
import MDBox from 'components/MDBox'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import RotateRightIcon from '@mui/icons-material/RotateRight'
// import staticPDF from '../../sample.pdf'
import VisibilityIcon from '@mui/icons-material/Visibility'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`

function MyDocument(props) {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [rotation, setRotation] = useState(0)
  const [scale, setScale] = useState(1.0)
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  const nextPage = () => {
    if (pageNumber < numPages) setPageNumber(pageNumber + 1)
  }

  const prevPage = () => {
    if (pageNumber > 1) setPageNumber(pageNumber - 1)
  }

  const rotateClockwise = () => {
    setRotation((prevRotation) => (prevRotation + 90) % 360)
  }

  const rotateCounterClockwise = () => {
    setRotation((prevRotation) => (prevRotation - 90) % 360)
  }

  const zoomIn = () => setScale((prev) => Math.min(prev + 0.2, 3.0)) // Max zoom 3.0x
  const zoomOut = () => setScale((prev) => Math.max(prev - 0.2, 0.5)) // Min zoom 0.5x

  return (
    <div className="pdf-div">
      <p>
        Page {pageNumber} of {numPages}
      </p>
      <div style={{ height: '500px', overflowY: 'auto' }}>
        {/* <Document file={staticPDF} onLoadSuccess={onDocumentLoadSuccess}>
          
          <Page key={pageNumber} pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} rotate={rotation} />
        </Document> */}

        <Document file={decodeURIComponent(props.pdfFile)} onLoadSuccess={onDocumentLoadSuccess}>
          <Page
            key={`${pageNumber}-${scale}-${rotation}`} // Ensure re-render when zoom or rotate changes
            pageNumber={pageNumber}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            rotate={rotation}
            scale={scale}
          />
        </Document>
      </div>
      <MDBox mt={2} sx={{ '& button': { m: 0.3 } }}>
        <MDButton onClick={prevPage} disabled={pageNumber === 1} variant="contained" color="primary" size="small">
          <ArrowBackIosNewIcon />
        </MDButton>
        <MDButton onClick={nextPage} disabled={pageNumber === numPages} variant="outlined" color="primary" size="small">
          <ArrowForwardIosIcon />
        </MDButton>
        <MDButton onClick={rotateCounterClockwise} variant="outlined" color="primary" size="small">
          <RotateLeftIcon />
        </MDButton>
        <MDButton onClick={rotateClockwise} variant="outlined" color="primary" size="small">
          <RotateRightIcon />
        </MDButton>
        <MDButton onClick={zoomOut} disabled={scale <= 0.5} variant="outlined" color="primary" size="small">
          <ZoomOutIcon />
        </MDButton>
        <MDButton onClick={zoomIn} disabled={scale >= 3.0} variant="outlined" color="primary" size="small">
          <ZoomInIcon />
        </MDButton>
        <MDButton component="a" href={decodeURIComponent(props.pdfFile)} target="_blank" variant="outlined" color="primary" size="small">
          <VisibilityIcon />
        </MDButton>
      </MDBox>
    </div>
  )
}

export default MyDocument
