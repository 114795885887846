import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import MDBox from 'components/MDBox'
import { Grid } from '@mui/material'
import MDTypography from 'components/MDTypography'
import ComplexStatisticsCard from 'examples/Cards/StatisticsCards/ComplexStatisticsCard'
import { useSelector, useDispatch } from 'react-redux'
import { getUsersTotalCount } from '../../redux/slice/user-handle'
import configs from 'examples/Charts/LineCharts/ReportsLineChart/configs'
import { Line } from 'react-chartjs-2'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler)

function Dashboard() {
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const { userDetails } = useSelector((state) => state.user)

  const token = localStorage.getItem('userToken')
  useEffect(() => {
    if (token) {
      dispatch(getUsersTotalCount(token))
    } else {
      navigate('/admin/sign-in')
    }
  }, [dispatch, token, navigate])

  const countsByStatus = userDetails?.countsByStatus || {}

  const currentMonthCount = userDetails?.userMonthlyRecords?.currentMonthCount || 0
  const lastMonthCount = userDetails?.userMonthlyRecords?.lastMonthCount || 0

  // Calculate the percentage change
  const percentageChange = lastMonthCount ? ((currentMonthCount - lastMonthCount) / lastMonthCount) * 100 : 0

  const { tasks } = {
    tasks: {
      labels: ['Total Users', 'Under Review', 'Creating NFT', 'Block', 'Resubmission', 'Rejected', 'Completed'],
      datasets: {
        label: 'Users',
        data: [
          userDetails?.totalUsersCount,
          countsByStatus.Under_review,
          countsByStatus.Processing,
          countsByStatus.Block,
          countsByStatus.Resubmission,
          countsByStatus.Rejected,
          countsByStatus.Completed,
        ],
      },
    },
  }

  const { data, options } = configs(tasks.labels || [], tasks.datasets || {})
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="people"
                title="Users"
                count={userDetails?.totalUsersCount || 0}
                percentage={{
                  color: 'dark',
                  amount: (percentageChange || 0).toFixed(2),
                  label: 'than lask month',
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="rate_review"
                title="Under Review Users"
                count={countsByStatus.Under_review || 0}
                percentage={{
                  color: 'dark',
                  amount: countsByStatus.Under_review || 0,
                  label: '- total under review users',
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="info"
                icon="account_tree_icon"
                title="Creating NFT Users"
                count={countsByStatus.Processing || 0}
                percentage={{
                  color: 'dark',
                  amount: countsByStatus.Processing || 0,
                  label: '- total creating NFT users',
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="light"
                icon="block"
                title="Block Users"
                count={countsByStatus.Block || 0}
                percentage={{
                  color: 'dark',
                  amount: countsByStatus.Block || 0,
                  label: '- total block users',
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <Grid container spacing={3} mt={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="secondary"
                icon="assignment_return_icon"
                title="Resubmission Users"
                count={countsByStatus.Resubmission || 0}
                percentage={{
                  color: 'dark',
                  amount: countsByStatus.Resubmission || 0,
                  label: '- total resubmission users',
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="error"
                icon="close"
                title="Rejected Users"
                count={countsByStatus.Rejected || 0}
                percentage={{
                  color: 'dark',
                  amount: countsByStatus.Rejected || 0,
                  label: '- total rejected users',
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="add_task"
                title="Completed Users"
                count={countsByStatus.Completed || 0}
                percentage={{
                  color: 'success',
                  amount: countsByStatus.Completed || 0,
                  label: '- total completed users',
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="warning"
                icon="attach_money"
                title="Payment Completed Users"
                count={userDetails?.totalPaymentUsersCount || 0}
                percentage={{
                  color: 'success',
                  amount: userDetails.totalPaymentUsersCount || 0,
                  label: '- total payment completed users',
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <MDBox mb={3}>
                <Card sx={{ height: '100%' }}>
                  <MDBox padding="1rem">
                    {useMemo(
                      () => (
                        <MDBox variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="dark" py={2} pr={0.5} mt={-5} height="12.5rem">
                          <Line data={data} options={options} redraw />
                        </MDBox>
                      ),
                      [data, options],
                    )}
                    <MDBox pt={3} pb={1} px={1}>
                      <MDTypography variant="h6" textTransform="capitalize">
                        User Report
                      </MDTypography>
                      <MDTypography component="div" variant="button" color="text" fontWeight="light">
                        User status update performance
                      </MDTypography>
                      <Divider />
                    </MDBox>
                  </MDBox>
                </Card>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  )
}

export default Dashboard
